@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;700;900&display=swap');

$grey-200: #f8f9fb;

/* Font sizes */
$font-xxlarge: 1.5rem;
$font-xlarge: 1.375rem;
$font-large: 1.125rem;
$font-default: 1rem;
$font-small: 0.875rem;
$font-xsmall: 0.75rem;
$font-xxsmall: 0.625rem;

/* colors */
$white: #fff;

.btn {
  &[disabled] {
    opacity: 0.3;
    cursor: default;
    color: $white;
}

  &.btn-primary {
    color: $white;
    &[disabled] {
      opacity: 0.3;
      cursor: default;
      color: $white;
  }
  &:hover {
    color: $white;
}
}

&.btn-outline-primary {
&:hover,&:not(:disabled):not(.disabled):active {
  color: $white;
}
}

}

$fonts: (
  "xxlarge": $font-xxlarge,
  "xlarge": $font-xlarge,
  "large": $font-large,
  "default": $font-default,
  "small": $font-small,
  "xsmall": $font-xsmall,
  "xxsmall": $font-xxsmall,
);

$theme-colors: (
  "primary": #26743A,
  "danger": #ff4136,
  "secondary": #F07522,
  "highlight": #86BF40
);

// text-{size}
@each $name, $font in $fonts {
  .text-#{$name} {
    font-size: $font !important;
  }
}

body {
  background-color: $grey-200;
  font-family: 'Poppins', sans-serif;
}
*{
  font-family: 'Poppins', sans-serif;
}

input:not([type="textarea"]).form-control,
select.form-control {
  height: 3rem !important;
}

// bootstrap override with Colors.grey400
.form-control {
  border-color: #e1e4e8 !important;
}

.react-icons {
  vertical-align: middle;
}
.vertical-align-middle {
  vertical-align: middle;
}

/* Text Mixins */

@mixin overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15.625rem;
}

@mixin text-not-selectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin transform-text($transform) {
  text-transform: $transform !important;
}

@mixin align-text($alignment) {
  text-align: $alignment !important;
}
.text-overflow-ellipsis {
  text-overflow: ellipsis;
}
.caret {
  margin-right: 1rem;
}

html,
body,
#__next {
  height: 100%;
}

#__next {
  display: flex;
  flex-direction: column;
}

.rotate {
  transform: rotate(-180deg);
}

.cursor-pointer {
  cursor: pointer;
}

@import '~bootstrap-scss/bootstrap';

// bootstrap override with Colors.greyBase800
.text-light {
  color: #97a3b4 !important;
}

.pointer{
  cursor: pointer;
}
.font-weight-500 {
  font-weight: 500;
}
